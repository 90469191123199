import { inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { CanActivateFn, Route, Router } from '@angular/router';
import { LandingComponent } from '@trueleap-plus/features/landing-page';
import {
  AuthContainerComponent,
  AuthService,
  AuthStore,
  LoginComponent,
  Logout,
  PasswordResetComponent,
  SignUpComponent,
} from '@trueleap/auth';
import { HOST_URL } from '@trueleap/ui-tokens';
import { map, tap } from 'rxjs';

export const isWorkSpaceUser = async () => {
  const authStore = inject(AuthStore);
  const router = inject(Router);
  const authService = inject(AuthService);
  const currentOrg = authStore.currentSubDomain();
  const authUser = authStore.profile();
  if (!authUser) {
    return router.createUrlTree(['/auth']);
  }
  const userCanLogin = authService.canLoginToOrg(currentOrg, authUser);
  if (!userCanLogin) {
    return router.createUrlTree(['/auth']);
  }
  return true;
};

export const canSeeLandingPage: CanActivateFn = async () => {
  const authStore = inject(AuthStore);
  const subDomain = authStore.currentSubDomain;
  const router = inject(Router);
  if (subDomain()) {
    return router.parseUrl('/courses');
  }
  return true;
};

export const notLoggedIn: CanActivateFn = () => {
  const authStore = inject(AuthStore);
  const authUser = authStore.profile();
  const router = inject(Router);
  if (authUser) {
    return router.parseUrl('/');
  }
  return true;
};

export const canSignUp: CanActivateFn = () => {
  const authStore = inject(AuthStore);
  const hostUrl = inject(HOST_URL);
  const subDomain = authStore.currentSubDomain();
  if (subDomain) {
    window.location.href = `https://${hostUrl}/auth/sign-up`;
    return false;
  }
  return true;
};

export const isLoggedIn = () => {
  const auth = inject(Auth);
  const router = inject(Router);
  return user(auth).pipe(
    map(user => !!user),
    tap(() => {
      if (!user) {
        router.navigate(['/']);
      }
    })
  );
};

export const appRoutes: Route[] = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        redirectTo: 'courses',
        pathMatch: 'full',
      },
      {
        path: 'courses',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.CourseContainerComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('@trueleap/course').then(m => m.PublicCourseList),
            data: {
              breadcrumb: {
                label: 'Courses',
              },
            },
          },
          {
            path: ':id',
            loadComponent: () =>
              import('@trueleap/course').then(m => m.PublicCourseDetail),
            data: {
              breadcrumb: {
                alias: 'courseDetails',
              },
            },
          },
        ],
      },
      {
        path: 'contents',
        loadComponent: () =>
          import('@trueleap-plus/content').then(m => m.ContentComponent),
      },
      {
        path: 'contents/:id',
        loadComponent: () =>
          import('@trueleap-plus/content').then(m => m.ContentComponent),
      },
      {
        path: 'events',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.EventsContainerComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('@trueleap-plus/features/webiner').then(
                m => m.PublicWebinerComponent
              ),
            data: {
              breadcrumb: {
                label: 'Events',
              },
            },
          },
          {
            path: ':id',
            loadComponent: () =>
              import('@trueleap-plus/features/webiner').then(
                m => m.PublicWebinerDetailsComponent
              ),
            data: {
              breadcrumb: {
                alias: 'eventDetails',
              },
            },
          },
        ],
        data: {
          breadcrumb: {
            label: 'Events',
          },
        },
      },
      {
        path: 'my-courses',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.MyCoursesComponent),
        data: {
          breadcrumb: {
            label: 'My Courses',
            info: { icon: 'library_books' },
          },
          routes: [
            {
              label: 'My Courses',
              icon: 'library_books',
              route: '/my-courses',
            },
            {
              label: 'My Events',
              icon: 'event',
              route: '/my-events',
            },
          ],
        },
      },
      {
        path: 'my-events',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.MyWebinerComponent
          ),
        data: {
          breadcrumb: {
            label: 'My Events',
            info: { icon: 'event' },
          },
          routes: [
            {
              label: 'My Courses',
              icon: 'library_books',
              route: '/my-courses',
            },
            {
              label: 'My Events',
              icon: 'event',
              route: '/my-events',
            },
          ],
        },
      },
    ],
  },
  {
    path: 'payment',
    loadChildren: () => import('@trueleap/payment').then(m => m.PaymentModule),
  },
  {
    path: 'auth',
    component: AuthContainerComponent,
    canActivate: [notLoggedIn],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
        canActivate: [canSignUp],
      },
      {
        path: 'reset-password',
        component: PasswordResetComponent,
      },
    ],
  },
  {
    path: 'auth/logout',
    component: Logout,
    canActivate: [isLoggedIn],
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('@trueleap-plus/ui/dashboard').then(m => m.DashboardComponent),
    canActivate: [isWorkSpaceUser],
    children: [
      {
        path: 'analytics',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.AnalyticsComponent),
        data: {
          breadcrumb: {
            label: 'Analytics',
            info: { icon: 'analytics' },
          },
          routes: [],
        },
      },
      {
        path: 'analytics/',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.AnalyticsComponent),
        data: {
          breadcrumb: {
            label: 'Analytics',
            info: { icon: 'analytics' },
          },
          routes: [],
        },
      },
      {
        path: 'content-management',
        loadChildren: () =>
          import('@trueleap/course').then(m => m.CourseRoutes),
        data: {
          breadcrumb: {
            label: 'Content Management',
            info: { icon: 'library_books' },
          },
          routes: [
            {
              label: 'All Courses',
              icon: 'library_books',
              route: '/dashboard/content-management/courses',
            },
            {
              label: 'Create Course',
              icon: 'add_circle',
              route: '/dashboard/content-management/courses/create',
            },
            {
              label: 'All Contents',
              icon: 'video_library',
              route: '/dashboard/content-management/contents',
            },
            {
              label: 'Create Content',
              icon: 'add_circle',
              route: '/dashboard/content-management/contents/create',
            },
          ],
        },
      },
      {
        path: 'events',
        loadChildren: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.featuresWebinerRoutes
          ),
        data: {
          routes: [
            {
              label: 'All Events',
              icon: 'list',
              route: '/dashboard/events',
            },
            {
              label: 'Create Event',
              icon: 'add_circle',
              route: '/dashboard/events/create',
            },
            {
              label: 'Events Recordings',
              icon: 'videocam',
              route: '/dashboard/events/recordings',
            },
          ],
          breadcrumb: {
            label: 'Events',
            info: { icon: 'event' },
          },
        },
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.DashboardHome),
        data: {
          breadcrumb: {
            label: 'Dashboard',
            info: { icon: 'dashboard' },
          },
          routes: [
            {
              label: 'Home',
              icon: 'home',
              route: '/dashboard/home',
            },
            {
              lable: 'Courses',
              icon: 'library_books',
              route: '/dashboard/content-management/courses',
            },
            {
              label: 'Contents',
              icon: 'video_library',
              route: '/dashboard/content-management/contents',
            },
            {
              label: 'Events',
              icon: 'event',
              route: '/dashboard/events',
            },
            {
              label: 'Institutions',
              icon: 'school',
              route: '/dashboard/lms/institute-management',
            },

            {
              label: 'My Purchases',
              icon: 'shopping_cart',
              route: '/dashboard/purchases/my-courses',
            },
            {
              label: 'My Files',
              icon: 'folder',
              route: '/dashboard/my-files',
            },
          ],
        },
      },
      {
        path: 'my-purchases',
        loadComponent: () =>
          import('@trueleap-plus/ui/my-purchase').then(
            m => m.MyPurchaseComponent
          ),
      },
      {
        path: 'lms',
        loadChildren: () =>
          import('@trueleap-plus/ui/lms').then(m => m.LmsRoutes),
        data: {
          breadcrumb: {
            label: 'Learning',
            info: { icon: 'school' },
          },
        },
      },
      {
        path: 'my-files',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.MyFiles),
        data: {
          breadcrumb: {
            label: 'My Files',
            info: { icon: 'folder' },
          },
          routes: [
            {
              label: 'My Files',
              icon: 'folder',
              route: '/dashboard/my-files',
            },
          ],
        },
      },

      {
        path: 'users',
        loadChildren: () => import('@trueleap/users').then(m => m.ROUTES),
        data: {
          breadcrumb: {
            label: 'Users',
            info: { icon: 'people' },
          },
          routes: [
            {
              label: 'All Users',
              icon: 'list',
              route: '/dashboard/users',
            },
            {
              label: 'Add User',
              icon: 'add_circle',
              route: '/dashboard/users/add',
            },
            {
              label: 'Directory',
              icon: 'domain',
              route: '/dashboard/users/directory',
            },
            {
              label: 'Import',
              icon: 'upload_file',
              route: '/dashboard/users/import',
            },
          ],
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@trueleap-plus/profile').then(m => m.PROFILE_ROUTES),
        data: {
          breadcrumb: {
            label: 'Profile',
            info: { icon: 'person' },
          },
        },
      },
      {
        path: 'purchases',
        loadComponent: () => import('@trueleap/course').then(m => m.Purchases),
        data: {
          breadcrumb: {
            label: 'Purchases',
            info: { icon: 'shopping_cart' },
          },
        },
        children: [
          {
            path: '',
            redirectTo: 'my-courses',
            pathMatch: 'full',
          },
          {
            path: 'my-courses',
            loadComponent: () =>
              import('@trueleap/course').then(m => m.MyCoursesComponent),
            data: {
              breadcrumb: {
                label: 'My Courses',
                info: { icon: 'library_books' },
              },
              routes: [
                {
                  label: 'My Courses',
                  icon: 'library_books',
                  route: '/dashboard/purchases/my-courses',
                },
                {
                  label: 'My Events',
                  icon: 'event',
                  route: '/dashboard/purchases/my-events',
                },
              ],
            },
          },
          {
            path: 'my-courses/:id',
            loadComponent: () =>
              import('@trueleap/course').then(m => m.PublicCourseDetail),
            data: {
              breadcrumb: {
                label: 'Course Details',
                info: { icon: 'library_books' },
              },
              routes: [
                {
                  label: 'My Courses',
                  icon: 'library_books',
                  route: '/dashboard/purchases/my-courses',
                },
                {
                  label: 'My Events',
                  icon: 'event',
                  route: '/dashboard/purchases/my-events',
                },
              ],
            },
          },
          {
            path: 'my-events',
            loadComponent: () =>
              import('@trueleap-plus/features/webiner').then(
                m => m.MyWebinerComponent
              ),
            data: {
              breadcrumb: {
                label: 'My Events',
                info: { icon: 'event' },
              },
              routes: [
                {
                  label: 'My Courses',
                  icon: 'library_books',
                  route: '/dashboard/purchases/my-courses',
                },
                {
                  label: 'My Events',
                  icon: 'event',
                  route: '/dashboard/purchases/my-events',
                },
              ],
            },
          },
          {
            path: 'my-events/:id',
            loadComponent: () =>
              import('@trueleap-plus/features/webiner').then(
                m => m.PublicWebinerDetailsComponent
              ),
            data: {
              breadcrumb: {
                label: 'Event Details',
                info: { icon: 'library_books' },
              },
              routes: [
                {
                  label: 'My Courses',
                  icon: 'library_books',
                  route: '/dashboard/purchases/my-courses',
                },
                {
                  label: 'My Events',
                  icon: 'event',
                  route: '/dashboard/purchases/my-events',
                },
              ],
            },
          },
        ],
      },

      {
        path: '**',
        loadComponent: () =>
          import('@trueleap/ui-kit').then(m => m.NotFoundComponent),
        data: {
          breadcrumb: {
            label: 'Page not found',
            info: { icon: 'error' },
          },
          routes: [],
        },
      },
    ],
    data: {
      breadcrumb: {
        label: 'Home',
        info: { icon: 'home' },
      },
    },
  },

  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];
