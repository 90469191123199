import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxLoadingBar } from '@ngx-loading-bar/core';

@Component({
    imports: [RouterOutlet, NgxLoadingBar],
    selector: 'trueleap-plus-root',
    template: `
    @defer (on immediate) {
      <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
    }
    <router-outlet></router-outlet>
  `,
    styles: [``]
})
export class AppComponent {}
